import { atom } from "recoil";
import { localStoragePersistRecoil } from "services/Local-services";

export const userInfo = atom({
    key: "userInfo",
    default: null,
});

export const userCredentials = atom({
    key: "userCredentials",
    default: {},
});

export const rioNegroServices = atom({
    key: "rioNegroServices",
    default: [],
});

export const routeInfo = atom({
    key: "routeInfo",
    default: {
        route: "/",
        wordKey: "Dashboard",
    },
});

export const formIsOpen = atom({
    key: "formIsOpen",
    default: false,
});

export const rowSelected = atom({
    key: "rowSelected",
    default: {
        status: false,
        row: {},
    },
});

export const snackbarData = atom({
    key: "snackbarData",
    default: {
        open: false,
    },
});

export const formData = atom({
    key: "formData",
    default: {
        title: "",
    },
});

export const modalData = atom({
    key: "modalIsOpen",
    default: {
        isOpen: false,
        confirmationModalOpen: false,
        info: null,
        action: null,
        title: "",
    },
});

export const secondaryModalData = atom({
    key: "secondaryModalIsOpen",
    default: {
        isOpen: false,
        confirmationModalOpen: false,
        info: null,
        action: null,
        title: "",
    },
});

export const breadcrumbState = atom({
    key: "breadcrumbState",
    default: [],
    effects: [localStoragePersistRecoil("breadcrumb_state")],
});

export const paginationState = atom({
    key: "paginationState",
    default: 0,
});
